import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {
  faVk,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faMessage, faCloud, faCocktail, faRecordVinyl, faStar} from '@fortawesome/free-solid-svg-icons'
import {useCallback, useEffect, useRef, useState} from "react";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import { YMInitializer } from 'react-yandex-metrika';
import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';
import LazyLoad from 'react-lazyload';
import Modal from "./components/modal";

const isFuture = (someDate) => {
    const today = new Date().getTime()
    return today < someDate + (86400000 / 2)
}

function App() {
  const videoRefs = useRef([])
  const sliderRef = useRef(null)
  const [isModalOpened, setModalOpened] = useState(false)
  const [modalText, setModalText] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)
  const [events, setEvents] = useState([])

    useEffect(() => {
        fetch('events.json')
            .then(response => response.json())
            .then(data => {
              setEvents(data)
            })
    }, [])

  const handleContactUs = useCallback(async () => {
     const message = window.prompt('Связь с нами. Не забудьте оставить свой контакт')
     if (message) {
       fetch(`https://seemseem.ru/form.php?message=${message}`).catch((err) => { console.log('msg err: ', err) })
     }
  }, [])

  const onSlideChange = useCallback((from, to) => {
      videoRefs.current[from]?.pause()
      setCurrentIndex(to + 1)
  }, [])

  const gallery = Array.from({length: 44}, (_, i) => i + 1).reverse().map((number) => ({
      src: `./photos/${number}.jpg`
  }));

  return (
    // backgroundImage: `url(${background})`}
    <div style={{flex: 1, height: '100%', backgroundColor: 'rgb(21, 21, 21)', paddingLeft: 12, paddingRight: 12}}>
      <YMInitializer accounts={[93746584]} />
      <div style={{ maxWidth: '900px', marginRight: 'auto', marginLeft: 'auto' }}>
        <div style={{display: "flex", paddingTop: 24, justifyContent: 'space-between'}}>
          <div style={{alignSelf: 'center', flex: 1}}>
              <div style={{color: 'white'}}>{'Фрунзе 5'}</div>
          </div>
          <img src={'./logo.png'} width={100} className="invert" />
          <div style={{display: 'block', flex: 1, alignSelf: 'center'}}>
            <div style={{color: 'white', display: 'flex', justifyContent: 'flex-end'}}>
              <a onClick={handleContactUs}
                 className="instagram social">
                <FontAwesomeIcon icon={faMessage} size="2x" />
              </a>
            </div>
              <div style={{color: 'white', display: 'flex', justifyContent: 'flex-end', marginRight: 16, marginTop: 10}}>
                 <a href={'https://t.me/SeemSeemClub'} target="_blank" rel="noopener noreferrer" >
                   <img src={'./tg.webp'} width={32} height={32} />
                 </a>
              </div>
          </div>
        </div>
        <div style={{color: 'white', textAlign: 'center', paddingTop: 10, paddingBottom: 15 }}>{'UPCOMING EVENT'}</div>
        {events.length ? <Slide ref={sliderRef} autoplay={false} onChange={onSlideChange}>
            {events.filter(item => isFuture(item.date)).sort((a, b) => a.date - b.date).map((item, index) => (
                <div className="each-slide-effect">
                    <div>
                        {item.video ? currentIndex === index ? null : (<video autoPlay={false} ref={ref => videoRefs.current[index] = ref} width="100%" height="600" poster={item.poster ? item.poster : './logo.png'} controls="controls" preload="metadata" loop>
                            <source src={`${item.video}`} type='video/mp4' />
                        </video>) : <img src={`${item.picture}`} width="100%" style={{'object-fit': 'contain'}} height="600"/>}
                        <CopyToClipboard text={item.info} onCopy={(text) => {
                            setModalText(text)
                            setModalOpened(true)
                        }}>
                            <a
                                className="copy social">
                                <FontAwesomeIcon icon={faCopy} size="2x" />
                                <div style={{marginLeft: 12}}>подробности</div>
                            </a>
                        </CopyToClipboard>
                    </div>
                </div>
            ))}
        </Slide> : <div style={{height: '600px', width: '100%'}} />}
          <h3 style={{color: 'white', marginBottom: 5}}>SEEM SEEM
              <FontAwesomeIcon style={{marginLeft: 8, bottom: 2}} icon={faStar} color={'black'} size="1x" />
              <FontAwesomeIcon style={{marginLeft: 8, bottom: 2}} icon={faStar} color={'black'} size="1x" />
              <FontAwesomeIcon style={{marginLeft: 8, bottom: 2}} icon={faStar} color={'black'} size="1x" />
              <FontAwesomeIcon style={{marginLeft: 8, bottom: 2}} icon={faStar} color={'black'} size="1x" />
              <FontAwesomeIcon style={{marginLeft: 8, bottom: 2}} icon={faStar} color={'black'} size="1x" />
          </h3>
          <div className="wideText">{"— это не просто ночной клуб, это культовое место для всех, кто ценит качественную электронную музыку и незабываемые эмоции. Уже более 5 лет мы создаем уникальную атмосферу, где каждый гость становится частью яркого музыкального действа.  \n" +
              "\n" +
              "Каждые выходные в SeemSeem звучат лучшие треки от талантливых диджеев, которые зажигают танцпол и дарят море позитива. Мы тщательно выбираем артистов, чтобы каждый вечер был особенным, а музыка — на высочайшем уровне.  \n" +
              "\n" +
              "Наш клуб — это место, где стиль, энергия и драйв сливаются воедино. Современный интерьер, мощная звуковая система и завораживающий свет создают неповторимую атмосферу, которая захватывает с первых минут.  \n" +
              "\n" +
              "SeemSeem — это больше, чем клуб. Это место, где рождаются эмоции, где каждый вечер становится маленьким праздником. Присоединяйтесь к нам, чтобы ощутить настоящий драйв и окунуться в мир качественной электронной музыки!  \n" +
              "\n" +
              "Ждем вас каждые выходные — в SeemSeem всегда хорошо! 🔥"}</div>
          <div style={{color: 'white', textAlign: 'center', paddingTop: 15, marginBottom: 0 }}>
              <a href="https://vk.com/seemseemnsk" target="_blank"
                 className="vk social">
                  <FontAwesomeIcon icon={faVk} size="2x" />
              </a>
              <a href="https://instagram.com/seem_seem_?igshid=YmMyMTA2M2Y=" target="_blank"
                 className="instagram social">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
              </a>
              <a href="https://t.me/Seem_Seem_club" target="_blank" className="telegram social">
                  <img src={'./tg.webp'} width={28} height={28} />
              </a>
          </div>
      </div>
        <div style={{justifyContent: 'center', display: 'flex', marginLeft: 24}}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
                {/*<div style={{display: 'flex', marginTop: 24}}>*/}
                {/*    <div style={{color: 'white', alignSelf: 'center', marginRight: 12}}>{'вс-чт'}</div>*/}
                {/*    <div style={{color: 'white'}}>{'18:00-24:00'}<FontAwesomeIcon style={{paddingLeft: 10, marginRight: 12}} icon={faCloud} size="1x" />LOUNGE</div>*/}
                {/*</div>*/}
                <div style={{display: 'flex', marginTop: 24}}>
                    <div style={{color: 'white', alignSelf: 'center', marginRight: 12}}>{'пятница и суббота'}</div>
                    <div>
                        {/*<div style={{color: 'white'}}>{'18:00-23:00'}<FontAwesomeIcon style={{paddingLeft: 10, marginRight: 12}} icon={faCloud} size="1x" />LOUNGE</div>*/}
                        <div style={{color: 'white'}}>{'23:00-06:00'}<FontAwesomeIcon style={{paddingLeft: 10}} icon={faCocktail} size="1x" /><FontAwesomeIcon icon={faRecordVinyl} size="1x" style={{marginRight: 12}} />PARTY</div>
                        <div style={{color: 'white'}}>{'06:00-10:00'}<FontAwesomeIcon style={{paddingLeft: 10}} icon={faCocktail} size="1x" /><FontAwesomeIcon icon={faRecordVinyl} size="1x" style={{marginRight: 12}} />{' AFTER'}</div>
                    </div>
                </div>
            </div>
        </div>
        <LazyLoad height={600}>
          <Carousel shouldLazyLoad={true} images={gallery} style={{ height: 600, width: '100%', alignSelf: 'center', marginTop: 16 }} />
        </LazyLoad>
        <iframe style={{borderWidth: 0, marginLeft: 'auto', marginRight: 'auto', marginTop: 24}} src="https://yandex.ru/map-widget/v1/?ll=82.916749%2C55.036577&mode=routes&theme=dark&rtext=~55.036687%2C82.916338&rtt=auto&ruri=~&z=9" width="100%" height="400" frameBorder="1" allowFullScreen="true" />
        {isModalOpened ? <Modal open={isModalOpened} setModalOpened={setModalOpened}>
            {modalText}
        </Modal> : null}
    </div>
  );
}

export default App;
